<template>
  <v-card class="pa-2 main-card" outlined>
    <h3>Sažetak aktivnosti u {{ currentYear }}.</h3>
    <v-divider></v-divider>

    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <ActivityGraph />
        </v-col>
        <v-col cols="12" sm="6">
          <v-card class="pa-2" outlined tile>
            <v-simple-table v-if="!loading">
              <template v-slot:default v-if="stats">
                <tbody>
                  <tr>
                    <td>Aktivnih članova</td>
                    <td class="active-members">
                      {{ stats.membershipStats.active }}
                    </td>
                  </tr>
                  <tr>
                    <td>Neaktivnih članova</td>
                    <td class="inactive-members">
                      {{ stats.membershipStats.inactive }}
                    </td>
                  </tr>
                  <tr>
                    <td>DPH rank</td>
                    <td class="rank">
                      {{ stats.currentRanking.position }} /
                      {{ stats.currentRanking.total }}
                    </td>
                  </tr>
                  <tr>
                    <td>Terenskih akcija odrađeno u {{ currentYear }}.</td>
                    <td>
                      {{ fieldActivityStats.fieldActivitiesInCurrentYearCount }}
                    </td>
                  </tr>
                  <tr>
                    <td>Ukupno dugovanje</td>
                    <td
                      :class="
                        unpaidAmountClass(fieldActivityStats.totalUnpaidAmount)
                      "
                    >
                      {{ fieldActivityStats.totalUnpaidAmount > 0 ? "+" : ""
                      }}{{
                        formatCurrency(fieldActivityStats.totalUnpaidAmount)
                      }}
                      €
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <div v-else>
              <v-progress-circular
                indeterminate
                color="#1358a3"
              ></v-progress-circular>
            </div>

            <p v-show="error" class="error-text">{{ error }}</p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import apiRequest from "../../helpers/apiRequest";
import ActivityGraph from "./ActivityGraph.vue";

import mixin from "../../mixins/mixin";

export default {
  name: "Stats",

  mixins: [mixin],

  components: {
    ActivityGraph,
  },

  data() {
    return {
      stats: {},
      fieldActivityStats: {},
      loading: false,
      error: false,
    };
  },

  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },

  async created() {
    await this.getStats();
  },

  methods: {
    async getStats() {
      this.loading = true;
      this.error = false;

      try {
        this.stats = await apiRequest("get", "dphs/me/stats");
        this.fieldActivityStats = await apiRequest(
          "get",
          "field-activities/my/stats"
        );
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },

    unpaidAmountClass(amount) {
      if (amount > 0) {
        return "text-green";
      } else if (amount < 0) {
        return "text-red";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table tr td {
  font-weight: bold;
}
.active-members {
  color: #27ae60;
  font-size: 18px !important;
}
.inactive-members {
  color: #ce3137;
}
</style>
