<template>
  <v-card class="pa-2 main-card" outlined>
    <h3>Osnovni podaci</h3>
    <v-divider></v-divider>

    <v-container fluid v-if="dph.sf_data">
      <v-row no-gutters>
        <v-col cols="12" sm="6">
          <v-card class="pa-2" outlined tile>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td><b>Kontakt osoba</b></td>
                    <td v-if="dph.sf_data.npe01__One2OneContact__r">
                      {{ dph.sf_data.npe01__One2OneContact__r.FirstName }}
                      {{ dph.sf_data.npe01__One2OneContact__r.LastName }}
                    </td>
                    <td v-else></td>
                  </tr>
                  <tr>
                    <td><b>Kontakt telefon</b></td>
                    <td>{{ dph.sf_data.Phone }}</td>
                  </tr>
                  <tr>
                    <td><b>Email</b></td>
                    <td>{{ dph.sf_data.Preferred_Email__c }}</td>
                  </tr>
                  <tr>
                    <td><b>Adresa</b></td>
                    <td v-if="dph.sf_data.BillingAddress">
                      {{ dph.sf_data.BillingAddress.street }} <br />
                      {{ dph.sf_data.BillingAddress.postalCode }}
                      {{ dph.sf_data.BillingAddress.city }} <br />
                      {{ dph.sf_data.BillingAddress.country }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card class="pa-2" outlined tile>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td><b>OIB</b></td>
                    <td>
                      {{ dph.sf_data.OIB__c }}
                    </td>
                  </tr>
                  <tr>
                    <td><b>Datum potpisa ugovora</b></td>
                    <td>
                      {{ formatDate(dph.sf_data.Contract_Signing_Date__c) }}
                    </td>
                  </tr>
                  <tr>
                    <td><b>Datum isteka ugovora</b></td>
                    <td>
                      {{ formatDate(dph.sf_data.Contract_Expiration_Date__c) }}
                    </td>
                  </tr>
                  <tr>
                    <td><b>Predsjednik</b></td>
                    <td v-if="dph.sf_data.President__r">
                      {{ dph.sf_data.President__r.FirstName }}
                      {{ dph.sf_data.President__r.LastName }}
                    </td>
                    <td v-else></td>
                  </tr>
                  <tr>
                    <td><b>Tajnik</b></td>
                    <td v-if="dph.sf_data.Secretary__r">
                      {{ dph.sf_data.Secretary__r.FirstName }}
                      {{ dph.sf_data.Secretary__r.LastName }}
                    </td>
                    <td v-else></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import mixin from "../../mixins/mixin";

export default {
  name: "Info",

  mixins: [mixin],

  computed: {
    dph() {
      return this.$store.state.account.user;
    },
  },
};
</script>
