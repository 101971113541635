<template>
  <v-col cols="12" class="dashboard-data">
    <Stats />

    <Info />
  </v-col>
</template>

<script>
import Stats from "../components/dashboard/Stats.vue";
import Info from "../components/dashboard/Info.vue";

export default {
  name: "Dashboard",

  components: { Stats, Info },
};
</script>

<style lang="scss">
.dashboard-data {
  h3 {
    margin-bottom: 16px;
  }
  .pa-2 {
    border: none;
  }
}
</style>
