<template>
  <v-card class="pa-2" outlined tile>
    <h4>Statistika učlanjivanja</h4>

    <div v-if="!loading && Object.keys(memberActivationStats).length !== 0">
      <v-select
        class="year-selector"
        :items="membershipYears"
        v-model="selectedYear"
        dense
      ></v-select>

      <Bar
        :chart-options="chartOptions"
        :chart-data="chartData"
        :height="chartHeight"
      />
    </div>

    <div v-if="loading">
      <v-progress-circular indeterminate color="#1358a3"></v-progress-circular>
    </div>

    <p v-show="error" class="error-text">{{ error }}</p>
  </v-card>
</template>

<script>
import apiRequest from "../../helpers/apiRequest";
import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "ActivityGraph",

  components: {
    Bar,
  },

  data() {
    return {
      memberActivationStats: {},
      loading: false,
      error: false,
      selectedYear: new Date().getFullYear(),
      chartHeight: 150,
      chartOptions: {
        responsive: true,
        backgroundColor: "#1976d2",
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };
  },

  async created() {
    await this.getMemberActivationStats();
  },

  computed: {
    chartData() {
      const labels = [
        "Siječanj",
        "Veljača",
        "Ožujak",
        "Travanj",
        "Svibanj",
        "Lipanj",
        "Srpanj",
        "Kolovoz",
        "Rujan",
        "Listopad",
        "Studeni",
        "Prosinac",
      ];

      let membershipsPerMonth = [];

      for (let i = 1; i <= 12; i++) {
        let monthData = this.memberActivationStats.filter((el) => {
          return el.year == this.selectedYear && el.month == i;
        });

        membershipsPerMonth.push(
          monthData.length ? monthData[0].members_count : 0
        );
      }

      return { labels, datasets: [{ data: membershipsPerMonth }] };
    },

    membershipYears() {
      return this.memberActivationStats
        .map((item) => item.year)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => b - a);
    },
  },

  methods: {
    async getMemberActivationStats() {
      this.loading = true;
      this.error = false;

      try {
        this.memberActivationStats = await apiRequest(
          "get",
          "dphs/me/membership-activation-stats"
        );
      } catch (error) {
        this.error = error.response.data.message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.year-selector {
  float: right;
  margin-top: -1.4em;
  width: 30%;
}
</style>
